import React from "react";
import { Helmet } from "react-helmet";

import { PageLayout } from "../layouts";
import { GetStartedBlock, Block, GetStartedCtas, TrackedLink } from "../components";

/**
 * @todo:
 * Need more / non-cropped assets for squad block.
 */
import SquadBlockImg1 from "../images/main-page/squad-1.svg";
import SquadBlockImg2 from "../images/main-page/squad-2.svg";
import SquadBlockImg3 from "../images/main-page/squad-3.svg";
import SquadBlockImg4 from "../images/main-page/squad-4.svg";
import SquadBlockImg5 from "../images/main-page/squad-5.svg";

/**
 * Hero assets.
 */
import Waves from "../images/global/waves.svg";
import Player1 from "../images/floating-gamer-cat.svg";
import Player2 from "../images/floating-gamer-popcorn.svg";
import BluePlanet from "../images/global/planet-blue.svg";
import PinkPlanet from "../images/global/planet-pink.svg";
import SpaceInvader from "../images/space-invader.svg";

import "../global/styles/page-styles/main-page.scss";

/**
 * Home page function.
 * @returns page
 */
const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Partie | More Than Matchmaking</title>
        <html lang="en" />
        <meta name="description" content="Partie makes gaming more accessible, inclusive and rewarding. Connect and play with others through all-in-one Partie Matchmaking, Voice, Video and Text Chat." />
      </Helmet>
      <PageLayout className="main-page">
        {/*
          Hero Block
        */}
        <Block centered hero lazy>
          <h2 className="partie-block__title">Better Together</h2>
          <p className="partie-block__description">
            Partie makes gaming more accessible, inclusive and rewarding.
            Connect and play with others through all-in-one Partie Matchmaking,
            Voice, Video and Text Chat.
          </p>
          <div className="partie-block__cta">
            <GetStartedCtas />
          </div>
          <div className="main-page-hero-background" aria-hidden>
            <img src={Waves} className="waves-1" alt="" aria-hidden />
            <img src={Waves} className="waves-2" alt="" aria-hidden />
            <img src={Player1} className="player-1" alt="" aria-hidden />
            <img src={Player2} className="player-2" alt="" aria-hidden />
            <img src={BluePlanet} className="blue-planet" alt="" aria-hidden />
            <img src={PinkPlanet} className="pink-planet" alt="" aria-hidden />
            <img
              src={SpaceInvader}
              loading="lazy"
              className="space-invader"
              alt=""
              aria-hidden
            />
          </div>
        </Block>

        {/* 
          Squad Block
        */}
        <Block centered className="squad-block" lazy>
          <div className="partie-block__column">
            <h3 className="partie-block__title">Find the Perfect Squad</h3>
            <p className="partie-block__description">
              Browse, Join and Create Parties based on the in-game objective
              you’d like to achieve and the number of people you’re looking to
              play with.
            </p>
            <div className="partie-block__cta">
              <TrackedLink type="btn btn--accent" to="/features" children="View all features" />
            </div>
          </div>
          <div className="partie-block__column">
            <div className="squad-block__image-array">
              <div className="squad-block__image-container">
                <img src={SquadBlockImg1} alt="Squad Image 1" loading="lazy" />
              </div>
              <div className="squad-block__image-container">
                <img src={SquadBlockImg2} alt="Squad Image 2" loading="lazy" />
              </div>
              <div className="squad-block__image-container">
                <img src={SquadBlockImg3} alt="Squad Image 3" loading="lazy" />
              </div>
              <div className="squad-block__image-container">
                <img src={SquadBlockImg4} alt="Squad Image 4" loading="lazy" />
              </div>
              <div className="squad-block__image-container">
                <img src={SquadBlockImg5} alt="Squad Image 5" loading="lazy" />
              </div>
            </div>
          </div>
        </Block>

        {/* 
          Partie Environment Block.
        */}
        <Block className="built-for-you-block" tinted lazy>
          <div className="partie-block__column" aria-hidden>
            <img
              loading="lazy"
              src="https://cdn.partie.com/downloads/assets/images/newsite/FreeSafeAndBuiltForYou.svg"
              alt=""
              aria-hidden
            />
          </div>
          <div className="partie-block__column">
            <h3 className="partie-block__title">Free, Safe &amp; Built for You</h3>
            <p className="partie-block__description">
              Partie offers a free, safe and premium environment to connect,
              play and share. We strive to eliminate toxicity and increase
              player-to-player accountability through positive gameplay
              experiences. We do not sell your data or share it with third
              parties for advertising purposes.
            </p>
            <div className="partie-block__cta">
              <TrackedLink type="btn btn--accent" to="/features" children="View all features" />
            </div>
          </div>
        </Block>

        {/*
          Partie Subscription Block.
        */}
        <Block className="subscription-block" lazy>
          <div className="partie-block__column">
            <h3 className="partie-block__title">Partie Subscription</h3>
            <p className="partie-block__description">
              Partie offers a free, safe and premium environment to connect and
              play with others through all-in-one Matchmaking, Voice, Video and
              Text Chat.
            </p>
            <div className="partie-block__cta">
              <TrackedLink type="btn btn--accent" to="/features" children="Discover" />
            </div>
          </div>
          <div className="partie-block__column" aria-hidden>
            <img
              loading="lazy"
              src="https://cdn.partie.com/downloads/assets/images/newsite/hand_phone.svg"
              alt=""
              aria-hidden
            />
          </div>
        </Block>
        <GetStartedBlock />
      </PageLayout>
    </>
  );
};

export default IndexPage;